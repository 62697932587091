<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: "What's your full name?",
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <div
    :class="[
      typewriterDone ? 'opacity-100' : 'opacity-0',
      'transition ease-linear duration-300 space-y-6',
    ]"
  >
    <Field name="name" label="Full name" rules="required" autocomplete="name" />

    <FormButtonNext />
  </div>
</template>
