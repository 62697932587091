<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'How would you describe your health?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="yourHealth"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Poor',
      },
      {
        label: 'Good',
      },
      {
        label: 'Great',
      },
    ]"
  />
</template>
