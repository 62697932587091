<script setup lang="ts">
const { gptMessage } = inject('form') as any

defineProps<{
  show: boolean
  delay: number
}>()
</script>

<template>
  <div
    v-if="gptMessage"
    :class="[
      show ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300 flex items-end gap-2 !mt-12',
    ]"
    :style="{ transitionDelay: `${delay}ms` }"
  >
    <div class="flex-1 p-4 bg-gray-200 rounded-md shadow">
      {{ gptMessage }}
    </div>
    <img src="../assets/emily-avatar.png" class="w-12 h-12 rounded-full" />
  </div>
</template>
