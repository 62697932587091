<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'Can I make you a personalized medicare guide?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter
    :text="`Hey! I am Emily. <br /> ${question} It's fast (2 mins).`"
    @done="typewriterDone = true"
  />

  <ButtonGroup
    name="makePersonalizedGuide"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Sure!',
      },
      {
        label: 'Nope',
      },
    ]"
  />
</template>
