<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: "What's one benefit you wish you had?",
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="oneBenefit"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Dental',
      },
      {
        label: 'Giveback',
      },
      {
        label: 'Grocery',
      },
      {
        label: 'Flex Card',
      },
      {
        label: 'Something else',
      },
    ]"
  />

  <GptMessage :show="typewriterDone" :delay="1500" />
</template>
