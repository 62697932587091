<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'Do you worry about healthcare costs?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="worryHealthcareCosts"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'No',
      },
      {
        label: 'A little',
      },
      {
        label: 'A lot',
      },
    ]"
  />
</template>
