<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'Do you have a primary care provider you like?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="primaryCareProvider"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'I don\'t want to have to change',
      },
      {
        label: 'I love my doctor, I\'d never leave',
      },
      {
        label: 'I don\'t have a primary care provider',
      },
      {
        label: 'I wish I had a better one',
      },
    ]"
  />
</template>
