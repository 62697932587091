<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'Are you sure? All I want to do is help you understand',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="areYouSure"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Ok I\'ll give it a try',
      },
      {
        label: 'No thanks',
      },
    ]"
  />
</template>
