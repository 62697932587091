<script setup lang="ts">
const { value: rateMedicarePlan } = useField('rateMedicarePlan', '', {
  initialValue: '3',
})

const { value: question } = useField('_question', '', {
  initialValue:
    'If you had to rate your current Medicare plan on a scale of 1 to 5 how would you rate it?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <div
    :class="[
      typewriterDone ? 'opacity-100' : 'opacity-0',
      'transition ease-linear duration-300 space-y-6',
    ]"
  >
    <div class="px-4 space-y-1">
      <input
        v-model="rateMedicarePlan"
        type="range"
        class="appearance-none block w-full h-4 cursor-pointer bg-gray-300 rounded-lg"
        min="1"
        max="5"
      />
      <div
        class="flex justify-between pointer-events-none text-gray-500 text-sm"
      >
        <div> 1 </div>
        <div> 2 </div>
        <div> 3 </div>
        <div> 4 </div>
        <div> 5 </div>
      </div>
    </div>

    <FormButtonNext />
  </div>
</template>
