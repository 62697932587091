<script setup lang="ts">
const props = defineProps<{
  name: string
  show: boolean
  buttons: {
    label: string
    value?: any
  }[]
}>()

const { value } = useField(props.name)
</script>

<template>
  <div class="space-y-3">
    <Button
      v-for="(button, index) in buttons"
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      :class="[
        show ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
        'transition ease-linear duration-300',
      ]"
      :style="{ transitionDelay: `${index * 300}ms` }"
      @click="value = button.value !== undefined ? button.value : button.label"
    >
      {{ button.label }}
    </Button>
  </div>
</template>
