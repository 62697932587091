<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: `Can I call you ${query.name}?`,
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="correctName"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ]"
  />
</template>
