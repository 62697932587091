<script setup lang="ts">
useHead({
  title: `Get a quote - ${brand.name}`,
})

const stepsSubmitted: {
  id: string
  question: string
  answer: string
  posted: boolean
}[] = []

const handleStepSubmit = (step: Record<string, any>) => {
  const question = step.values._question
  const answer = _.find(step.values, (value, key) => !key.startsWith('_'))

  if (!question) return

  stepsSubmitted.push({
    id: step.id,
    question,
    answer,
    posted: false,
  })

  const phone = stepsSubmitted.find((step) => step.id === 'phone')?.answer

  if (phone) {
    const phoneFormatted = `+1${phone.replace(/-/g, '')}`

    axios.post(`${import.meta.env.VITE_XAVIER_URL}/public/quiz/step`, {
      phoneNumber: phoneFormatted,
      questions: stepsSubmitted
        .filter((step) => !step.posted)
        .map((step) => {
          return {
            question: step.question,
            answer: step.answer,
          }
        }),
    })

    stepsSubmitted.forEach((step) => {
      step.posted = true
    })

    if (step.id === 'phone') {
      axios.post(`${import.meta.env.VITE_XAVIER_URL}/public/quiz/start`, {
        phoneNumber: phoneFormatted,
      })
    }

    if (step.id === 'oneBenefit') {
      const name =
        stepsSubmitted.find((step) => step.id === 'name')?.answer || query.name

      axios.post(`${import.meta.env.VITE_XAVIER_URL}/public/quiz/finish`, {
        phoneNumber: phoneFormatted,
        title: name ? `${name}'s Medicare Guide` : 'Your Medicare Guide',
        imageUrl: `${window.location.protocol}//${window.location.host}${brand.logo.url}`,
        questions: stepsSubmitted.map((step) => {
          return {
            question: step.question,
            answer: step.answer,
          }
        }),
      })
    }
  }
}
</script>

<template>
  <Layout header="emily" class="container flex flex-col">
    <div class="flex-1 py-16">
      <UseForm v-slot="{ values }">
        <Form
          id="askEmily"
          :steps="[
            { id: 'start' },
            { id: 'areYouSure', if: values?.makePersonalizedGuide === 'Nope' },
            {
              id: 'dq',
              if:
                values?.makePersonalizedGuide === 'Nope' &&
                values?.areYouSure === 'No thanks',
            },
            { id: 'confirmName', if: !!query.name },
            { id: 'name', if: values?.correctName !== 'Yes' },
            { id: 'confirmState', if: !!query.state },
            { id: 'state', if: values?.correctState !== 'Yes' },
            { id: 'phone' },
            { id: 'medicareKnowledge' },
            { id: 'yourHealth' },
            { id: 'frequentlyUsedServices' },
            { id: 'primaryCareProvider' },
            { id: 'moreImportant' },
            { id: 'rateMedicarePlan' },
            { id: 'healthcareCosts' },
            { id: 'oneBenefit' },
            { id: 'loading' },
            { id: 'thankYou' },
          ]"
          product="medicare"
          @step-submit="handleStepSubmit"
        />
      </UseForm>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["med10"],
    "robots": "noindex"
  }
}
</route>
