<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'Where can I send your personal medicare guide?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <div
    :class="[
      typewriterDone ? 'opacity-100' : 'opacity-0',
      'transition ease-linear duration-300 space-y-6',
    ]"
  >
    <Field
      type="phone"
      name="primaryphone"
      label="Phone number"
      rules="required"
      :initial-value="query.phone"
    />

    <FormButtonNext />

    <div class="text-gray-500 text-sm sm:text-base text-justify">
      This is a solicitation for insurance for products including Medicare
      Advantage, Medicare Supplement, and Prescription Drug Plans. There is no
      obligation to enroll. A response and/or your call may generate
      communications by a licensed insurance agent, insurance producer,
      insurance provider or insurance company. Submission of your information
      does NOT affect your current enrollment, nor will it enroll you in a
      Medicare Supplement plan or other Medicare plan.
      <br />
      <br />
      By clicking the 'next' button above, you provide your signature expressly
      consenting to receive communications via live telephone, an automatic
      dialing system, pre-recorded message, artificial voice message, or text
      message from EasyHealth, its subsidiaries, or partner
      <Link to="/carriers" target="_blank">companies</Link> at the telephone
      number provided including your wireless number (if provided) as well as
      via email regarding your health insurance options. Your consent to receive
      communications in this way is not required as a condition of purchasing
      any goods or services. Your telephone company may impose additional
      charges for text messages, and you may revoke your consent at any time
      through any reasonable manner.
    </div>
  </div>
</template>
