<script setup lang="ts">
import Typewriter from 'typewriter-effect/dist/core'

const emit = defineEmits<{
  (e: 'done'): void
}>()

const props = defineProps<{
  text: string
  speed?: number
}>()

const el = ref<HTMLElement | null>(null)

let typewriterInstance: any

onMounted(() => {
  typewriterInstance = new Typewriter(el.value)
  typewriterInstance
    .changeDelay(props.speed ?? 30)
    .typeString(props.text)
    .start()
    .callFunction(() => {
      emit('done')
    })
})

onBeforeUnmount(() => {
  typewriterInstance?.stop()
})
</script>

<template>
  <h1 ref="el" class="text-2xl sm:text-3xl text-center !mb-8"></h1>
</template>
