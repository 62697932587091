<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'Do you know a lot about medicare?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="medicareKnowledge"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'It\'s really confusing',
      },
      {
        label: 'I have a basic understanding',
      },
      {
        label: 'I\'m an expert',
      },
    ]"
  />
</template>
