<script setup lang="ts">
const { value: question } = useField('_question', '', {
  initialValue: 'What type of healthcare services do you use most frequently?',
})

const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter :text="question" @done="typewriterDone = true" />

  <ButtonGroup
    name="frequentlyUsedServices"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Prescription Drug Coverage',
      },
      {
        label: 'Primary Care Doctor Visits',
      },
      {
        label: 'Specilty Doctor Visits',
      },
      {
        label: 'Hospital Visits',
      },
    ]"
  />
</template>
